import React, { useState, useEffect } from "react"

import SEO from "../components/seo"

import ProfileCards from "../components/profilecards"

import Design from "../components/design-block"

import Development from "../components/development-block"

import Footer from "../components/footer"

import styled from "styled-components"

import LogoScotoma from "../images/scotomalabwhite.svg"

const Container = styled.div`
  overflow: hidden;
  #smiley {
    position: relative;
    display: inline-block;
  }

  #smiley-container {
    position: relative;
    display: inline-block;
  }
`

const Header = styled.div`
  font-family: "Averta Semibold";
  font-size: 3rem;
  color: white;
  padding: 1rem 1rem;

  @media (min-width: 576px) {
    font-size: 5rem;
    padding: 0;
    padding-left: 3rem;
  }
`

const SubHeader = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.5rem;
  color: white;
  padding: 0 1.3rem;

  @media (min-width: 576px) {
    font-size: 2.5rem;
    padding: 1rem 3.6rem;
  }
`

const Spacer = styled.div`
  height: 8rem;
  width: 100vw;

  @media (min-width: 576px) {
    height: 15rem;
  }
`

const SmallSpacer = styled.div`
  height: 4rem;
  width: 100vw;

  @media (min-width: 576px) {
    height: 10rem;
  }
`

const TextBlock = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 4rem;

  @media (min-width: 576px) {
    margin-bottom: 0;
    padding: 1.5rem;
    flex-direction: row;
  }
`

const LeftCol = styled.div`
  position: relative;
  padding: 1rem;
  flex-basis: 100%;

  @media (min-width: 576px) {
    padding: 1.5rem 2rem;
    flex-basis: 50%;
    top: -9px;
  }
`

const RightCol = styled.div`
  padding: 1rem;
  flex-basis: 100%;

  @media (min-width: 576px) {
    // padding-top: 2rem;
    padding: 1.5rem;
    flex-basis: 50%;
  }
`

const InlineScotoma = styled.span`
  display: inline-block;
  height: auto;
  width: 180px;
  padding-right: 7px;

  img {
    height: 100%;
    width: 100%;
  }
`

const WorkPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 350)
  }, [])

  useEffect(() => {
    var controller = new window.ScrollMagic.Controller()

    var tl = new window.gsap.timeline()
    var tl2 = new window.gsap.timeline()

    tl2.to("#smiley", 0.2, {
      rotate: -30,
      ease: window.Circ.easeInOut,
      onComplete: () => {
        tl2.fromTo(
          "#smiley",
          0.2,
          {
            rotate: -30,
            scale: 1,
            ease: window.Circ.easeInOut,
          },
          {
            rotate: 30,
            scale: 1.1,
            ease: window.Circ.easeInOut,
            yoyo: true,
            repeat: -1,
          }
        )
      },
    })

    // var animation2 = window.gsap.fromTo(
    //   "#smiley-container",
    //   3,
    //   {
    //     scale: 1,
    //     ease: window.Elastic.easeInOut,
    //   },
    //   {
    //     scale: 4,
    //     ease: window.Elastic.easeInOut,
    //   }
    // )

    tl.to("#smiley-container", 0, {
      scale: 1,
    })
      .to("#smiley-container", 2, {
        scale: 2.5,
        ease: window.Elastic.easeOut,
      })
      .to("#smiley-container", 1, {
        scale: 1,
        ease: window.Elastic.easeInOut,
      })

    tl.eventCallback("onComplete", function() {
      window.gsap.to("#smiley", 0.5, {
        rotate: 0,
        scale: 1,
        onComplete: () => {
          tl2.pause()
          scene.destroy(true)
          scene2.destroy(true)
        },
      })
    })

    // tl.fromTo(
    //   "#smiley",
    //   5,
    //   {
    //     x: 0,
    //     rotate: 0,
    //     ease: window.Power4.easeOut,
    //   },
    //   {
    //     x: 500,
    //     rotate: 720,
    //     ease: window.Power4.easeOut,
    //   }
    // )

    var scene = new window.ScrollMagic.Scene({
      triggerElement: "#meet-the-team",
      triggerHook: 0.5,
      // duration: "300%",
    })
      .setTween(tl2)
      .addTo(controller)

    var scene2 = new window.ScrollMagic.Scene({
      triggerElement: "#meet-the-team",
      triggerHook: 0.5,
      // duration: "300%",
    })
      .setTween(tl)
      .addTo(controller)

    return () => {
      scene.destroy(true)
      scene2.destroy(true)
    }
  })

  return (
    <>
      <SEO title="Lab" />
      <Container>
        <Spacer />
        {/* <Header>About us.</Header> */}
        <TextBlock>
          <LeftCol>
            <InlineScotoma>
              <img src={LogoScotoma} />
            </InlineScotoma>
            is a textile-first creative studio based in Paris, France. We design
            and develop fashion collections and textile-related projects by
            leveraging our trend expertise, 3D Technologies, and a
            pattern-making savoir-faire in order to meet our clients’ needs
            whilst fulfilling cost & time requirements.
            <br />
            Constantly pushing forward, we strive to use cutting-edge tools and
            processes to solve contemporary problems whilst keeping in mind
            cost-effectiveness alongside environmental and ethical issues.
          </LeftCol>
          <RightCol>
            Our fully equipped Parisian atelier has all the necessary tools to
            bring your next ambitious project to life. <br /> <br />
            Our studio was born from a desire to synergise our diverse and
            specific skill sets, experiment, research and explore new solutions
            in an effort to find better alternatives to current paradigms.
          </RightCol>
        </TextBlock>
        <SmallSpacer />
        <Design />
        <SmallSpacer />
        <Development />
        <Spacer />
        <Header id="meet-the-team">Meet the team.</Header>
        <SubHeader>
          Hello, it's great to see you!{" "}
          <span id="smiley-container">
            <span id="smiley">🖐🏻</span>
          </span>
        </SubHeader>
        <ProfileCards />
        <Footer />
      </Container>
    </>
  )
}

export default WorkPage
