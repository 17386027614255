import React, { useState, useEffect } from "react"

import styled from "styled-components"

import posed from "react-pose"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

// import ScrollMagic from "scrollmagic"
// import gsap, { Power4, Bounce, Elastic } from "gsap"
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

// ScrollMagicPluginGsap(ScrollMagic, gsap)

const Container = styled.div``

const Header = styled.div`
  font-family: "Averta Semibold";
  font-size: 3rem;
  color: white;
  padding: 1rem 1rem;

  @media (min-width: 576px) {
    font-size: 6.5rem;
    padding: 0;
    padding-left: 3rem;
  }
`

const SubHeader = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.5rem;
  color: white;
  padding: 0 1.3rem;

  @media (min-width: 576px) {
    font-size: 2.5rem;
    padding: 1rem 3.6rem;
  }
`

const HorizontalThreePlaceholder = styled.div`
  display: flex;
  width: 100vw;
  height: auto;
  padding: 0 1rem 0 1rem;
  margin: 3rem 0;
  box-sizing: border-box;
  align-items: center;

  .gatsby-image-wrapper {
    height: auto;
    width: 100%;
  }

  @media (min-width: 576px) {
    padding: 0 3rem 0 3rem;
  }
`

const Line = styled.div`
  display: block;
  width: 1px;
  min-width: 1px;
  height: 25vw;
  background-color: white;
  max-height: 350px;
`

const LineSecond = styled.div`
  display: block;
  width: 1px;
  min-width: 1px;
  height: 15rem;
  background-color: white;

  @media (min-width: 576px) {
    height: 25vw;
    max-height: 350px;
  }
`

const Title = styled.div`
  font-family: "Averta Semibold";
  font-size: 3vw;
  color: white;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding-right: 3rem;

  span {
    position: relative;
    white-space: nowrap;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotateZ(180deg);
  }

  @media (min-width: 576px) {
    display: flex;
  }
`

const SubTitle = styled.div`
  font-family: "Averta Semibold";
  font-size: 0.5rem;
  color: white;
  text-align: center;
  line-height: 1rem;

  @media (min-width: 576px) {
    font-size: 1.2vw;
    line-height: 1.9rem;
  }
`

const HorizontalThreePlaceholderInner = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InterTitle = styled.div`
  font-family: "Averta Semibold";
  font-size: 2.5rem;
  color: white;
  padding: 4rem 1rem;

  @media (min-width: 576px) {
    font-size: 3rem;
    padding: 12rem 6rem;
  }
`

const Stats = styled.div``

const InnerStat = styled.div`
  text-align: center;
  margin-bottom: 2.5rem;

  div:nth-child(1) {
    font-family: "Averta Semibold";
    font-size: 0.5rem;
    color: white;
    margin-bottom: 1rem;
  }

  div:nth-child(2) {
    font-family: "Averta Semibold";
    font-size: 2.5rem;
    color: white;
  }

  span:nth-child(1) {
    display: inline-block;
    width: 50px;
  }

  span:nth-child(2) {
    font-size: 0.5rem;
  }

  @media (min-width: 576px) {
    text-align: center;
    margin-bottom: 5rem;

    div:nth-child(1) {
      font-family: "Averta Semibold";
      font-size: 1.2vw;
      color: white;
      margin-bottom: 1rem;
    }

    div:nth-child(2) {
      font-family: "Averta Semibold";
      font-size: 4.5vw;
      color: white;
    }

    span:nth-child(1) {
      display: inline-block;
      width: 80px;
    }

    span:nth-child(2) {
      font-size: 1.2vw;
    }
  }
`

const HorizontalThreePlaceholderSpeed = styled.div`
  display: flex;
  width: 100vw;
  height: auto;
  padding: 0 1rem 0 1rem;
  margin: 3rem 0rem 0rem 0;
  box-sizing: border-box;
  align-items: space-between;

  @media (min-width: 576px) {
    padding: 0 3rem 0 3rem;
    margin: 3rem 0 0rem 0;
  }
`

const HorizontalThreePlaceholderSpeedInner = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100vw;
`

const ImageContainerText = styled.div`
  position: absolute;
  z-index: 1;
  font-family: "Averta Semibold";
  font-size: 1.5rem;
  color: white;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  text-align: center;

  @media (min-width: 576px) {
    font-size: 2.5rem;
  }
`

const Development = () => {
  const images = useStaticQuery(graphql`
    {
      imageOne: file(relativePath: { eq: "images/pattern.png" }) {
        id
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      imageTwo: file(relativePath: { eq: "images/topvirtual.png" }) {
        id
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      imageThree: file(relativePath: { eq: "images/topreal.png" }) {
        id
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `)

  useEffect(() => {
    var controller = new window.ScrollMagic.Controller()

    //Virtual Sample Div

    var tl5 = new window.gsap.timeline()

    tl5.staggerFromTo(
      ["#image-item-1", "#image-item-2", "#image-item-3"],
      2,
      {
        opacity: 0,
        y: 15,
        ease: window.Power4.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: window.Power4.easeInOut,
      },
      0.15
    )

    var scene3 = new window.ScrollMagic.Scene({
      triggerElement: "#virtual-sampling-container",
      triggerHook: 0.75,
    })
      .setTween(tl5)
      .addTo(controller)

    //Speed Div

    var tl = window.gsap.timeline()
    var tl2 = window.gsap.timeline()

    var counter = { value: 0 },
      counter2 = { value: 0 },
      NewVal = 37,
      NewVal2 = 27

    var updateCounter = function() {
      document.getElementById("counter-1").innerHTML = counter.value
    }

    var updateCounter2 = function() {
      document.getElementById("counter-2").innerHTML = counter2.value
    }

    tl.fromTo(
      counter,
      3,
      {
        value: 0,
        roundProps: "value",
        // ease: Power4.easeOut,
        onUpdate: updateCounter,
      },
      {
        value: NewVal,
        roundProps: "value",
        // ease: Power4.easeOut,
        onUpdate: updateCounter,
      }
    ).fromTo(
      "#percentage-1",
      2,
      {
        opacity: 0,
        ease: window.Power4.easeOut,
      },
      {
        opacity: 1,
        ease: window.Power4.easeOut,
      }
    )

    tl2
      .fromTo(
        counter2,
        2,
        {
          value: 0,
          roundProps: "value",
          // ease: Power4.easeOut,
          onUpdate: updateCounter2,
        },
        {
          value: NewVal2,
          roundProps: "value",
          // ease: Power4.easeOut,
          onUpdate: updateCounter2,
        }
      )
      .fromTo(
        "#percentage-2",
        2,
        {
          opacity: 0,
          ease: window.Power4.easeOut,
        },
        {
          opacity: 1,
          ease: window.Power4.easeOut,
        }
      )

    var scene = new window.ScrollMagic.Scene({
      triggerElement: "#stage",
      triggerHook: 0.75,
    })
      // .addIndicators({
      //   colorTrigger: "white",
      //   colorStart: "white",
      //   colorEnd: "white",
      //   indent: 5
      // })
      .setTween(tl)
      .addTo(controller)

    var scene2 = new window.ScrollMagic.Scene({
      triggerElement: "#stage",
      triggerHook: 0.5,
    })
      // .addIndicators({
      //   colorTrigger: "white",
      //   colorStart: "white",
      //   colorEnd: "white",
      //   indent: 5
      // })
      .setTween(tl2)
      .addTo(controller)

    return () => {
      scene.destroy(true)
      scene2.destroy(true)
      scene3.destroy(true)
    }
  }, [])

  return (
    <>
      <Container>
        <Header>Development.</Header>
        <SubHeader>Ultra-fast development with 3D Technology.</SubHeader>

        {/* Virtual Sampling */}

        <HorizontalThreePlaceholder id="virtual-sampling-container">
          <Title>
            <span>Virtual Sampling</span>
          </Title>
          <HorizontalThreePlaceholderInner id="image-item-1">
            <Img fluid={images.imageOne.childImageSharp.fluid} />
            <SubTitle>2D Digital Pattern</SubTitle>
          </HorizontalThreePlaceholderInner>

          <Line />
          <HorizontalThreePlaceholderInner id="image-item-2">
            <Img fluid={images.imageTwo.childImageSharp.fluid} />
            <SubTitle>Virtual Sample</SubTitle>
          </HorizontalThreePlaceholderInner>
          <Line />
          <HorizontalThreePlaceholderInner id="image-item-3">
            <Img fluid={images.imageThree.childImageSharp.fluid} />
            <SubTitle>Manufactured Sample</SubTitle>
          </HorizontalThreePlaceholderInner>
        </HorizontalThreePlaceholder>

        {/* Speed */}

        <InterTitle>11x Increase in production speed.</InterTitle>

        <HorizontalThreePlaceholderSpeed>
          <Title>
            <span>Speed</span>
          </Title>
          <HorizontalThreePlaceholderSpeedInner>
            <InnerStat>
              <div>Sample Lead Time</div>
              <div>
                <span id="counter-1">37</span>
                <span>days</span>
              </div>
            </InnerStat>
            <InnerStat>
              <div>Sample Adoption Rate</div>
              <div id="percentage-1">15%</div>
            </InnerStat>
            <SubTitle>
              Before
              <br />
              (without 3D)
            </SubTitle>
          </HorizontalThreePlaceholderSpeedInner>

          <LineSecond />
          <HorizontalThreePlaceholderSpeedInner id="stage">
            <InnerStat>
              <div>Sample Lead Time</div>
              <div>
                <span id="counter-2">27</span>
                <span>hrs</span>
              </div>
            </InnerStat>
            <InnerStat>
              <div>Sample Adoption Rate</div>
              <div id="percentage-2">55%</div>
            </InnerStat>
            <SubTitle>
              After
              <br />
              (with 3D)
            </SubTitle>
          </HorizontalThreePlaceholderSpeedInner>
        </HorizontalThreePlaceholderSpeed>

        {/* Image */}

        {/* <ImageContainer>
          <ImageContainerText>
            Endless possibilities and unlimited iterations.
          </ImageContainerText>
          <Img fluid={images.imageFour.childImageSharp.fluid} />
        </ImageContainer> */}
      </Container>
    </>
  )
}

export default Development
