import React, { useRef, useEffect } from "react"

import ProfileCard from "./profilecard"

// import { Tween, Timeline } from "react-gsap"

// import { Controller, Scene } from "react-scrollmagic"

import styled from "styled-components"

import VideoOne from "../videos/optimized/one.mp4"
import VideoTwo from "../videos/optimized/two.mp4"
import VideoThree from "../videos/optimized/three.mp4"
import VideoFour from "../videos/optimized/four.mp4"

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  pointer-events: all;
`

const ProfileCardContainer = styled.div`
  height: calc(0.4 * 100vw);
  flex-basis: 33%;
  width: 25%;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform: translate3d(0px, 0px, 0px) ! important;
  
`

const ProfileCardsDesktop = () => {
  const profileCardsContainerRef = useRef()

  let information = [
    {
      name: "Samuel",
      role: "Developer / Designer",
      age: "26",
      emoji: "⚡️",
      bio:
        "In an effort to leverage state-of-the-art web technologies and friction-less User Experiences (UX), Samuel Bassett develops portfolios, webshops, and digital platforms optimised for performance and durability, offering future-proof solutions combined with modern and bold aesthetics.",
    },
    {
      name: "Irene",
      role: "Designer / Stylist",
      age: "30",
      emoji: "👽",
      bio:
        "Irene Astete is a stylist and designer. During her time as a student in Paris she developed a quirky, left-field vision stemming from her many years at the conservatory of music, a fond interest in the arts, and a fascination for science. She is currently working as an in-house jersey developer at Balenciaga.",
    },
    {
      name: "Grégoire",
      role: "Fashion Designer / Pattern Maker / Trend Forecaster",
      age: "26",
      emoji: "💾",
      bio:
        "Grégoire Willerval, a multi-award winning fashion designer, is best defined by: optimisation, irony, and serendipity. As a lover of the arts, a design aficionado and die-hard sports fan, he strives to help clients communicate their vision in the best possible way.",
    },
    {
      name: "Caroline",
      role: "Fashion Designer",
      age: "24",
      emoji: "🌞",
      bio:
        "Caroline Dussuel is a fashion designer combining creativity and savoir-faire. Constantly on the lookout for conscious and innovative solutions, her forward-thinking mindset paired with 3D Pattern-Making skills make her a strong asset for garment prototyping and a valuable source of new solutions tailored to clients’ needs and the market.",
    },
	{
      name: "Elena",
      role: "Production Manager",
      age: "29",
      emoji: "🌞",
      bio:
        "Elena is a production manager specializing in the audiovisual sector. After working for Arte France, she puts all her savoir-faire at the service of the French and international music scene. She joined ScotomaLab in December 2020 to develop commercial relations and PR. ",
    },
  ]

  useEffect(() => {
    var controller = new window.ScrollMagic.Controller()

    var tl = new window.gsap.timeline()
    var tl2 = new window.gsap.timeline()
    var tl3 = new window.gsap.timeline()
    var tl4 = new window.gsap.timeline()

    tl.fromTo(
      "#profile-card-1",
      1,
      {
        y: 0,
        ease: window.Power4.easeOut,
      },
      {
        y: -50,
        ease: window.Power4.easeOut,
      }
    )

    tl2.fromTo(
      "#profile-card-2",
      1,
      {
        y: 0,
        ease: window.Power4.easeOut,
      },
      {
        y: 20,
        ease: window.Power4.easeOut,
      }
    )

    tl3.fromTo(
      "#profile-card-3",
      1,
      {
        y: 0,
        ease: window.Power4.easeOut,
      },
      {
        y: -100,
        ease: window.Power4.easeOut,
      }
    )

    tl4.fromTo(
      "#profile-card-4",
      1,
      {
        y: 0,
        ease: window.Power4.easeOut,
      },
      {
        y: 120,
        ease: window.Power4.easeOut,
      }
    )

    var scene = new window.ScrollMagic.Scene({
      triggerElement: "#profile-cards-container",
      triggerHook: 0.5,
      duration: "200%",
    })
      .setTween(tl)
      .addTo(controller)

    var scene2 = new window.ScrollMagic.Scene({
      triggerElement: "#profile-cards-container",
      triggerHook: 0.5,
      duration: "200%",
    })
      .setTween(tl2)
      .addTo(controller)

    var scene3 = new window.ScrollMagic.Scene({
      triggerElement: "#profile-cards-container",
      triggerHook: 0.5,
      duration: "200%",
    })
      .setTween(tl3)
      .addTo(controller)

    var scene4 = new window.ScrollMagic.Scene({
      triggerElement: "#profile-cards-container",
      triggerHook: 0.5,
      duration: "200%",
    })
      .setTween(tl4)
      .addTo(controller)

    return () => {
      scene.destroy(true)
      scene2.destroy(true)
      scene3.destroy(true)
      scene4.destroy(true)
    }
  })

  return (
    <Container ref={profileCardsContainerRef} id="profile-cards-container">
      
      {/* */}
      {/* Card Two */}

      <ProfileCardContainer id="profile-card-2">
        <ProfileCard videoUrl={VideoTwo} information={information[1]} />
      </ProfileCardContainer>

      {/* Card Two */}
      {/* */}
      {/* Card Three */}

      <ProfileCardContainer id="profile-card-3">
        <ProfileCard videoUrl={VideoThree} information={information[2]} />
      </ProfileCardContainer>

      {/* Card Three */}
	  {/* */}
      {/* Card Five */}

      <ProfileCardContainer id="profile-card-4">
        <ProfileCard videoUrl={VideoFour} information={information[4]} />
      </ProfileCardContainer>

      {/* Card Five */}
      {/* */}
      
    </Container>
  )
}

export default ProfileCardsDesktop
