import React, { useState, useEffect } from "react"

import posed from "react-pose"

import styled from "styled-components"

import Arrowdown from "../images/icons/Arrowdown.svg"

import World from "../images/icons/world.svg"

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
`

const preVisualHolder = posed.div({
  open: {
    //   delayChildren: 200,
    //   staggerChildren: 30,
    y: "85%",
    opacity: 1,
    transition: {
      duration: 750,
      ease: "easeInOut",
    },
  },
  close: {
    y: 0,
    opacity: 1,
    // delay: 150,
    transition: {
      duration: 500,
      ease: "easeInOut",
    },
  },
})

const VisualHolder = styled(preVisualHolder)`
  border-radius: 25px;
  overflow: hidden;
  z-index: 1;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const preInformation = posed.div({
  open: {
    delayChildren: 200,
    staggerChildren: 30,
    y: -20,
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
  close: {
    y: 0,
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
})

const preInformationContainer = posed.div({
  open: {
    delayChildren: 200,
    staggerChildren: 30,
    y: "100%",
    opacity: 0,
    transition: {
      duration: 500,
      ease: "easeInOut",
    },
  },
  close: {
    y: 0,
    opacity: 1,
    delay: 150,
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
})

const InformationContainer = styled(preInformationContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 95%;
  z-index: 2;
`

const preBioContainer = posed.div({
  open: {
    // delayChildren: 200,
    // staggerChildren: 30,
    x: "0",
    opacity: 1,
    transition: {
      duration: 350,
      ease: "easeInOut",
      //   opacity: {
      //     delay: 350,
      //   },
    },
  },
  close: {
    x: "0%",
    opacity: 1,
    // applyAtEnd: {
    //   x: "100%",
    // },
    transition: {
      duration: 250,
      ease: "easeInOut",
    },
  },
})

const BioContainer = styled(preBioContainer)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  //   backdrop-filter: blur(50px);
`

const Information = styled(preInformation)`
  position: absolute;
  bottom: 0;
  color: white;
  font-family: Averta Regular;
  padding: 0 0 2rem 2rem;
`

const Name = styled.div`
  font-family: Averta Semibold;
  font-size: 1rem;
  line-height: 1rem;
`

const Role = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 0.5rem;
`

const Location = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  margin-top: 0.5rem;

  span {
    position: relative;
    top: 0.15rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    padding: 0 0.3rem 0 0;
  }

  span img {
    height: 100%;
    width: 100%;
  }
`

const InformationSecondary = styled.div`
  position: absolute;
  top: 0;
  color: white;
  font-family: Averta Regular;
  padding: 2rem;
`

const NameSecondary = styled.span`
  font-family: Averta Semibold;
  font-size: 1rem;
  line-height: 1rem;
`

const RoleSecondary = styled.span`
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 0.5rem;
`

const LocationSecondary = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  margin-top: 0.5rem;

  span {
    position: relative;
    top: 0.15rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    padding: 0 0.3rem 0 0;
  }

  span img {
    height: 100%;
    width: 100%;
  }
`

const Text = styled.div`
  font-family: Averta Regular;
  margin-top: 2rem;
  line-height: 130%;
  font-size: 0.9rem;
`

const preArrow = posed.div({
  open: {
    y: 0,
    opacity: 1,
    delay: 200,
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
  close: {
    y: "100%",
    opacity: 0,
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
})

const Arrow = styled(preArrow)`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  img {
    width: 100%;
    height: 100%;
  }
`

const ProfileCard = props => {
  const [showArrow, setShowArrow] = useState(false)
  const [showBio, setShowBio] = useState(false)

  const toggleArrow = () => {
    setShowArrow(!showArrow)
  }

  const toggleBio = () => {
    setShowBio(!showBio)
  }

  return (
    <Container
      onMouseEnter={() => toggleArrow()}
      onMouseLeave={() => toggleArrow()}
      onMouseDown={() => toggleBio()}
    >
      <BioContainer>
        <InformationSecondary>
          <NameSecondary>{props.information.name}</NameSecondary>
          <RoleSecondary>
            {" "}
            | {props.information.age} | {props.information.role}
          </RoleSecondary>
          <LocationSecondary>
            <span>
              <img src={World} />
            </span>
            Paris, France
          </LocationSecondary>
          <Text>{props.information.bio}</Text>
        </InformationSecondary>
      </BioContainer>
      <VisualHolder pose={showBio ? "open" : "close"}>
        {/* <img src={ProfileOne} /> */}
        <video playsInline autoPlay muted loop>
          <source src={props.videoUrl} type="video/mp4" />
        </video>
      </VisualHolder>
      <InformationContainer pose={showBio ? "open" : "close"}>
        <Information pose={showArrow ? "open" : "close"}>
          <Name>
            {props.information.name}, {props.information.age}{" "}
            {props.information.emoji}
          </Name>
          <Role>{props.information.role}</Role>
          <Location>
            <span>
              <img src={World} />
            </span>
            Paris, France
          </Location>
        </Information>

        <Arrow pose={showArrow ? "open" : "close"}>
          <img src={Arrowdown} />
        </Arrow>
      </InformationContainer>
    </Container>
  )
}

export default ProfileCard
