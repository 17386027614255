import React, { useState, useEffect } from "react"

import styled from "styled-components"

import posed from "react-pose"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

// import gsap, { Power4, Bounce, Elastic, TimelineMax } from "gsap"
// import ScrollMagic from "scrollmagic"
// import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

// ScrollMagicPluginGsap(ScrollMagic, gsap)

const Container = styled.div``

const Header = styled.div`
  font-family: "Averta Semibold";
  font-size: 3rem;
  color: white;
  padding: 1rem 1rem;

  @media (min-width: 576px) {
    font-size: 6.5rem;
    padding: 0;
    padding-left: 3rem;
  }
`

const SubHeader = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.5rem;
  color: white;
  padding: 0 1.3rem;

  @media (min-width: 576px) {
    font-size: 2.5rem;
    padding: 1rem 3.6rem;
  }
`

const TextBlock = styled.div`
  font-family: "Averta Semibold";
  font-size: 1.3rem;
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  pointer-events: all;

  @media (min-width: 576px) {
    padding: 1.5rem;
    flex-direction: row;
  }
`

const LeftCol = styled.div`
  padding: 1rem;
  flex-basis: 100%;
  padding: 3rem 0;

  @media (min-width: 576px) {
    padding: 1.5rem;
    flex-basis: 60%;
  }
`

const RightCol = styled.div`
  padding: 1rem;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 576px) {
    padding: 1.5rem 1.5rem;
    flex-basis: 40%;
  }
`

const preDropdownText = posed.div({
  open: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeInOut",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      ease: "easeInOut",
    },
  },
})

const DropdownText = styled(preDropdownText)`
  font-family: "Averta Semibold";
  font-size: 1.1rem;
  padding-left: 0.3rem;
  margin-bottom: 2rem;
  margin-top: 1rem;

  .link a {
    color: rgb(255, 255, 255, 1);
    text-decoration: none;
    border-bottom: 1px solid rgb(255, 255, 255, 1);
  }
`

const preExpand = posed.div({
  open: {
    delayChildren: 50,
    staggerChildren: 50,
    maxHeight: 300,
    applyAtStart: { display: "block" },
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
  closed: {
    maxHeight: 0,
    delay: 350,
    applyAtEnd: { display: "none" },
    transition: {
      duration: 350,
      ease: "easeInOut",
    },
  },
})

const DropdownContainer = styled(preExpand)`
  cursor: pointer;
  // padding-bottom: 2rem;
`

const DropdownTitle = styled.div`
  font-family: "Averta Semibold";
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  font-size: 2.5rem;

  @media (min-width: 576px) {
    font-size: 5vw;
  }
`

const Squares = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`

const preSquare = posed.div({
  open: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeInOut",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      ease: "easeInOut",
    },
  },
})

const Square = styled(preSquare)`
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-left: 0.5rem;

  img {
    height: 100%;
    width: auto;
  }
`

const Design = () => {
  const [openDropdown, setOpenDropDown] = useState([
    { open: false },
    { open: false },
    { open: false },
    { open: false },
  ])

  const images = useStaticQuery(graphql`
    {
      people: file(relativePath: { eq: "peeps.png" }) {
        id
        publicURL
        childImageSharp {
          fluid(quality: 90) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      colors: allFile(filter: { absolutePath: { regex: "/colors/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      fabrics: allFile(filter: { absolutePath: { regex: "/fabrics/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      details: allFile(filter: { absolutePath: { regex: "/details/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  useEffect(() => {
    var controller = new window.ScrollMagic.Controller()

    var tl = new window.gsap.timeline()

    tl.staggerFromTo(
      ["#list-item-1", "#list-item-2", "#list-item-3", "#list-item-4"],
      2,
      {
        opacity: 0,
        y: 25,
        ease: window.Power4.easeOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: window.Power4.easeOut,
      },
      0.25
    )

    var scene = new window.ScrollMagic.Scene({
      triggerElement: "#list",
      triggerHook: 0.5,
    })
      .setTween(tl)
      .addTo(controller)

    return () => {
      scene.destroy(true)
    }
  }, [])

  const toggleDropdown = index => {
    const data = [...openDropdown]
    data[index].open = !data[index].open
    setOpenDropDown(data)
  }

  const data = [
    {
      title: "Moodboard",
      text:
        'Get access to our in-house curated trend forecasts. <br/><br/> <span class="link"><a href="/trends" target="_blank">See our forecasts.</a><span>',
    },
    {
      title: "Colors",
      text:
        "Advice on color selection according to seasonal trends and color ranges.",
      images: images.colors,
    },
    {
      title: "Fabrics",
      text:
        "Choice of fabrics for your garments inline with your requirements and product specifications.",
      images: images.fabrics,
    },
    {
      title: "Details",
      text: "Guidance on garment elements.",
      images: images.details,
    },
  ]

  console.log(images.colors)

  return (
    <>
      <Container>
        <Header>Design.</Header>
        <SubHeader>We guide you through your design process.</SubHeader>
        <TextBlock id="list">
          <LeftCol>
            <Img fluid={images.people.childImageSharp.fluid} />
          </LeftCol>
          <RightCol>
            {data.map((item, index) => {
              return (
                <>
                  <DropdownTitle
                    onClick={() => toggleDropdown(index)}
                    id={`list-item-${index + 1}`}
                  >
                    {item.title}
                    <span>{openDropdown[index].open ? "-" : "+"}</span>
                  </DropdownTitle>
                  <DropdownContainer
                    pose={openDropdown[index].open ? "open" : "closed"}
                  >
                    <DropdownText
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                    {item.images ? (
                      <Squares>
                        {item.images.edges.map(item => (
                          <Square>
                            <img src={item.node.publicURL} />
                          </Square>
                        ))}
                      </Squares>
                    ) : null}
                  </DropdownContainer>
                </>
              )
            })}
          </RightCol>
        </TextBlock>
      </Container>
    </>
  )
}

export default Design
