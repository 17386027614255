import React, { useEffect, useRef } from "react"

import ProfileCard from "./profilecardmobile"

import styled from "styled-components"

import VideoOne from "../videos/optimized/one.mp4"
import VideoTwo from "../videos/optimized/two.mp4"
import VideoThree from "../videos/optimized/three.mp4"
import VideoFour from "../videos/optimized/four.mp4"


const Container = styled.div`
  width: 100vw;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0;
  margin-top: 3rem;
  pointer-events: all;

  ::-webkit-scrollbar {
    display: none;
  }
`

const ProfileCardContainer = styled.div`
  flex-basis: 33%;
`

const ContainerInner = styled.div`
  display: flex;
  width: 400vw;
  flex-direction: row;
`

const ProfileCardsMobile = () => {
  let containerInnerRef = useRef()

  let information = [
    {
      name: "Samuel",
      role: "Developer / Designer",
      age: "26",
      emoji: "⚡️",
      bio:
        "In an effort to leverage state-of-the-art web technologies and friction-less User Experiences (UX), Samuel Bassett develops portfolios, webshops, and digital platforms optimised for performance and durability, offering future-proof solutions combined with modern and bold aesthetics.",
    },
    {
      name: "Irene",
      role: "Designer / Stylist",
      age: "30",
      emoji: "👽",
      bio:
        "Irene Astete is a stylist and designer. During her time as a student in Paris she developed a quirky, left-field vision stemming from her many years at the conservatory of music, a fond interest in the arts, and a fascination for science. She is currently working as an in-house jersey developer at Balenciaga.",
    },
    {
      name: "Grégoire",
      role: "Fashion Designer / Pattern Maker / Trend Forecaster",
      age: "26",
      emoji: "💾",
      bio:
        "Grégoire Willerval, a multi-award winning fashion designer, is best defined by: optimisation, irony, and serendipity. As a lover of the arts, a design aficionado and die-hard sports fan, he strives to help clients communicate their vision in the best possible way.",
    },
    {
      name: "Caroline",
      role: "Fashion Designer",
      age: "24",
      emoji: "🌞",
      bio:
        "Caroline Dussuel is a fashion designer combining creativity and savoir-faire. Constantly on the lookout for conscious and innovative solutions, her forward-thinking mindset paired with 3D Pattern-Making skills make her a strong asset for garment prototyping and a valuable source of new solutions tailored to clients’ needs and the market.",
    },
	{
      name: "Elena",
      role: "Production Manager",
      age: "29",
      emoji: "🌞",
      bio:
        "Elena is a production manager specializing in the audiovisual sector. After working for Arte France, she puts all her savoir-faire at the service of the French and international music scene. She joined ScotomaLab in December 2020 to develop commercial relations and PR. ",
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      containerInnerRef.current.scrollTo(100, 0)
    }, 0)
  }, [])

  return (
    <Container ref={containerInnerRef} className="container-inner">
      <ContainerInner>
        
        {/* */}
        {/* Card Two */}

        <ProfileCardContainer>
          <ProfileCard videoUrl={VideoTwo} information={information[1]} />
        </ProfileCardContainer>

        {/* Card Two */}
        {/* */}
        {/* Card Three */}

        <ProfileCardContainer>
          <ProfileCard videoUrl={VideoThree} information={information[2]} />
        </ProfileCardContainer>
		
		{/* Card Five */}

        <ProfileCardContainer>
          <ProfileCard videoUrl={VideoFour} information={information[4]} />
        </ProfileCardContainer>

        
      </ContainerInner>
    </Container>
  )
}

export default ProfileCardsMobile
